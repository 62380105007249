<template>
    <div>
        <div class="data-form">
            <ValidationObserver ref="importForm">
                <div class="row">
                    <div class="col-12">
                        <ValidationProvider name="file" rules="required" v-slot="{errors}">
                            <b-form-group :label="$t('excel_file')">
                                <div class="d-flex custom-file-upload">
                                    <b-form-file
                                        v-model="defineForm.file"
                                        :state="errors[0] ? false : null"
                                        :placeholder="$t('select_file')"
                                        :drop-placeholder="$t('drop_file')"
                                        ref="fileInput"
                                        multiple></b-form-file>
                                    <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}</b-button>
                                </div>
                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
<!--                        <b-form-group :label="$t('excel_format')">-->
<!--                            <div class="alert alert-info">-->
<!--                                {{$t('course_prerequisites_import_excel')}}-->
<!--                            </div>-->
<!--                        </b-form-group>-->
                    </div>
                    <div class="col-12 d-flex justify-content-between">
                        <b-button variant="success" @click="sampleExcel">{{ $t('excel_file') }}</b-button>
                        <processing-button :processing="processing"
                                           :label="$t('import')"
                                           variant="primary"
                                           @click="sendForm"/>
                    </div>
                </div>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
import ProcessingButton from "@/components/elements/ProcessingButton";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import CoursePrerequisitesService from "@/services/CoursePrerequisitesService";

export default {
    components: {
        ProcessingButton,
        ValidationObserver,
        ValidationProvider,
    },

    data() {
        return {
            defineForm: {
                file: null
            },
            processing: false
        }
    },
    methods: {
        async sampleExcel(){
            const headers = [
                { header: "Course Code", key: "", width: 30 },
                { header: "Prerequisite Course Code", key: "", width: 30 },
                { header: `Department Code (Prerequisite)`, key: "course_code1", width: 35 },
                { header: "Can it be taken in the same semester (1 or 0)", key: "student_number", width: 30 },
            ]


            this._downloadExcelFile({data:[{}], filename : `${this.$t('courseprerequisites')}.xlsx`, headers})
        },
        async sendForm() {
            const isValid = await this.$refs.importForm.validate();
            if (isValid) {
                this.processing = true
                let formData = new FormData();
                formData.append('file', this.defineForm.file[0])

                CoursePrerequisitesService.importExcel(formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.importForm)
                    })
                    .finally(() => {
                        this.processing = false
                    })

            }
        }
    }
}
</script>
