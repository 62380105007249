import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/prerequisites/', config);
}

const show = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/prerequisites/' + id);
}
const get = async (code) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/prerequisites/' + code);
}
const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    if (data.id) {
        return Api.put('/course/prerequisites/' + data.id, data);
    }
    return Api.post('/course/prerequisites/', data);
}
const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/course/prerequisites/' + id, formData);
}
const deleteCoursePrerequisites = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/course/prerequisites/' + id);
}
const importExcel = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/course/prerequisites/import', config);
}

export default {
    getAll,
    show,
    get,
    update,
    store,
    deleteCoursePrerequisites,
    importExcel
}
